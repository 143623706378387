.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.imgMain {
  border: groove 1em red;
  border-radius: 50%;
  align-items: center;
  justify-content: space-around;
  width: 300px;
}

.nameMain {
  background-color: #685128;
  border-radius: 10%;
  color: white;
  text-shadow: 2px 2px 0 black;
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background-image: url("./portal.png");
  background-size: 250px;
}

.super {
  background-image: url("./logo.png");
  background-position: left 2px top;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}

.superMain {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.superImg {
  margin-inline-end: .5rem;
}

.superData {
  padding: .5rem;
  /* background-color: #000; */
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 15px;
  font-weight: bold;
  /* font-size: 1vw; */
  -webkit-text-stroke: .3px #61dafb;
  border: groove 1em red;
}

.page {
  display: inline-flex;
  color: #61dafb;
  font-size: 1.5vw;
  font-weight: bold;
  text-transform: uppercase;
  border: groove .5em red;
  border-radius: 15px;
  padding: .5rem;
  background-color: rgba(0, 0, 0, 0.7)
}

select {
  margin: .5rem 1rem;
  -webkit-appearance: none;
  padding: 0 12px 0 12px;
  /* width: 100%; */
  border: 1px solid #E8EAED;
  border-radius: 5px;
  background: #61dafb;
  box-shadow: 0 1px 3px -2px #9098A9;
  cursor: pointer;
  font-size: 1vw;
  transition: all 150ms ease;
}

label {
  padding: 0 1rem 0 1rem;
  text-shadow: 2px 2px 0 black;
}

.imgGrid {
  cursor: pointer;
  border: .2rem solid #000;
  transition: all .5s ease-in-out;
}

.imgGrid:hover {
  /* filter: saturate(180%)*/
  border: none;
}

.character:hover .imgGrid {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.character {
  overflow: hidden;
}

.next, .back {
  cursor: pointer;
  font-size: 2rem;
}

@media (max-width: 750px) {
  .grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .character:hover .imgGrid {
    -webkit-transform: none;
    transform: none;
  }
  .imgMain {
    width: 150px;
  }
  .superData {
    font-size: 3vw;
  }
  .page {
    width: 90%;
    font-size: 20px;
  }
  select {
    margin: 0;
    font-size: 14px;
    color: #000;
  }
  label {
    padding: 0;
  }
}